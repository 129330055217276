import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _68045938 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _816aae60 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _649e66c7 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _fb1b212c = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _d2e3b016 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _7bb22c7b = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _4143c44a = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _ad39a1f2 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _7cfc3f73 = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _aa483466 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _2f264e18 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _4d7a8595 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _1eb6e9ed = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _24586d19 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _65605218 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _66e240fe = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _27bd86cf = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _68045938,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _816aae60,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _649e66c7,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _fb1b212c,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _d2e3b016,
    name: "receipt"
  }, {
    path: "/search",
    component: _7bb22c7b,
    name: "search"
  }, {
    path: "/search-booking",
    component: _4143c44a,
    name: "search-booking"
  }, {
    path: "/terms-and-conditions",
    component: _ad39a1f2,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _7cfc3f73,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _aa483466,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _2f264e18,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _4d7a8595,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _1eb6e9ed,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _24586d19,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/product",
    component: _65605218,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _66e240fe,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _27bd86cf,
    name: "index"
  }, {
    path: "/dedicated",
    component: _27bd86cf,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-index"
  }, {
    path: "/dedicated/search",
    component: _7bb22c7b,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-search"
  }, {
    path: "/dedicated/select-extras/product",
    component: _65605218,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-select-extras"
  }, {
    path: "/dedicated/manage-booking/modify",
    component: _4d7a8595,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-modify"
  }, {
    path: "/dedicated/receipt",
    component: _d2e3b016,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-receipt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
